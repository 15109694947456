@tailwind base;
@tailwind components;
@tailwind utilities;

/* Set the default text color to text-teal-700 */
@layer base {
  html {
    @apply text-teal-700;
  } 
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}

.react-calendar__tile--active {
  background: #006972;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #168999;
}